// 交易明细管理
<template>
  <div class="jobs">
    <div class="topShow">
      <el-row>
        <el-col :span="24">
          <div class="title-name">账户信息</div>
        </el-col>
      </el-row>
      <el-row>
       <el-col :span="userType=='system'?4:8" >
          <div class="trabeBoxOut">
            <div class="heightBox flex-space-between">
              <div class="flex-start-nowrap">
                <img class="trabeImg" :src="require(`@/assets/images/trabe1.png`)" alt="" />
                <div>
                  <div class="trabeTitle">账户余额</div>
                  <div>
                    <span class="price">{{
                      userType == "system" ? "--" : informationList.balance
                    }}</span>
                    <span class="unit">元</span>
                  </div>
                </div>
              </div>
              <buy ref="buy" v-if="userType != 'system'" />
            </div>
            <div class="heightBox bottomBg">
              <el-row :gutter="20">
                <el-col :span="7">
                  <div class="trabeItem">
                 
                  </div>
                </el-col>
                <el-col :span="9">
                  <div class="trabeItem1">
                        <div>
                       <div class="trabeTitle1">本月充值</div>
                       <div class="priceOther1">
                        {{informationList.topUp }}元
                      </div>
                    </div>
                  </div>
                </el-col>
                <el-col :span="7">
                  <div class="trabeItem">
                    <!-- <div class="priceOther1">{{ informationList.topUp }}元</div> -->
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
          <el-col :span="6" v-if="userType=='system'">
          <div class="trabeBoxOut">
            <div class="heightBox flex-space-between">
              <div class="flex-start-nowrap">
                <img class="trabeImg" :src="require(`@/assets/images/trabe1.png`)" alt="" />
                <div>
                  <div class="trabeTitle">渠道用户总余额</div>
                    <div>
                    <span class="price">{{
                      totalBalance.toFixed(2)
                    }}</span>
                    <span class="unit">元</span>
                  </div>
                </div>
              </div>
             
            </div>
            <div class="heightBox bottomBg">
            <el-row :gutter="20">
                <el-col :span="8">
                  <div class="trabeItem1">
                    <div>
                      <div class="trabeTitle1">API用户余额</div>
                      <div class="priceOther1">
                        {{ priceMap[0].toFixed(2) }}元
                      </div>
                    </div>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="trabeItem1">
                    <div>
                      <div class="trabeTitle1">平台用户(服务商结算)</div>
                      <div class="priceOther1">
                        {{ priceMap[1].toFixed(2) }}元
                      </div>
                    </div>
                  </div> 
                </el-col>
                <el-col :span="8">
                  <div class="trabeItem1">
                    <div>
                      <div class="trabeTitle1">平台用户(数字云结算)</div> 
                      <div class="priceOther1">
                        {{ priceMap[2].toFixed(2) }}元
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
                 <!-- <div class="trabeItem" style="padding:0 10px 0 10px" >
                    <div class="trabeTitle" >
                      {{totalBalance}}元(API用户余额{{ priceMap[0] }}元+平台用户(服务商结算){{ priceMap[1] }}元+平台用户(数字云结算){{ priceMap[2] }}元)
                    </div>
                  </div> -->
           
         
            </div>
          </div>
        </el-col>
        <el-col :span="userType=='system'?7:8">
          <div class="trabeBoxOut">
            <div class="heightBox flex-space-between">
              <div class="flex-start-nowrap">
                <img class="trabeImg" :src="require(`@/assets/images/trabe3.png`)" alt="" />
                <div>
                  <div class="trabeTitle">本月消费</div>
                  <div>
                    <span class="price">{{ informationList.thistotal }}</span>
                    <span class="unit">元</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="heightBox bottomBg">
              <el-row :gutter="20">
                <el-col :span="6">
                  <div class="trabeItem">
                    <div>
                      <div class="trabeTitle1">呼叫消费</div>
                      <div class="priceOther1">
                        {{ informationList.thisprivacy }}元
                      </div>
                    </div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="trabeItem">
                    <div>
                      <div class="trabeTitle1">语音质检消费</div>
                      <div class="priceOther1">
                        {{ informationList.thisvoice }}元
                      </div>
                    </div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="trabeItem">
                    <div>
                      <div class="trabeTitle1">黑名单消费</div>
                      <div class="priceOther1">
                        {{ informationList.thisblack }}元
                      </div>
                    </div>
                  </div>
                </el-col>
                <el-col :span="6" >
                  <div class="trabeItem" >
                    <div >
                      <div class="trabeTitle1">小号消费(截止时{{informationList.rentCount==null?'0':informationList.rentCount}}个小号)</div>
                      <div class="priceOther1">
                        {{ informationList.rentPrice==null?'0': informationList.rentPrice}}元
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
        <el-col :span="userType=='system'?7:8">
          <div class="trabeBoxOut">
            <div class="heightBox flex-space-between">
              <div class="flex-start-nowrap">
                <img class="trabeImg" :src="require(`@/assets/images/trabe2.png`)" alt="" />
                <div>
                  <div class="trabeTitle">上月消费</div>
                  <div>
                    <span class="price">{{ informationList.total }}</span>
                    <span class="unit">元</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="heightBox bottomBg">
              <el-row :gutter="20">
                <el-col :span="6">
                  <div class="trabeItem">
                    <div>
                      <div class="trabeTitle1">呼叫消费</div>
                      <div class="priceOther1">
                        {{ informationList.privacy }}元
                      </div>
                    </div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="trabeItem">
                    <div>
                      <div class="trabeTitle1">语音质检消费</div>
                      <div class="priceOther1">
                        {{ informationList.voice }}元
                      </div>
                    </div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="trabeItem">
                    <div>
                      <div class="trabeTitle1">黑名单消费</div>
                      <div class="priceOther1">
                        {{ informationList.black }}元
                      </div>
                    </div>
                  </div>
                </el-col>
                <el-col :span="6" >
                  <div class="trabeItem">
                    <div>
                      <div class="trabeTitle1">小号消费(截止时{{informationList.lastrentCount==null?'0':informationList.lastrentCount}}个小号)</div>
                      <div class="priceOther1">
                        {{ informationList.lastrentPrice==null?'0': informationList.lastrentPrice}}元
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="screening">
      <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item title="筛选框" name="1">
          <div class="screening-out">
            <el-form :inline="true" :model="searchFrom" class="demo-form-inline">
              <el-row>
                <el-col :span="22">
                  <el-form-item label="公司名称：" v-if="userType == 'system'">
                    <el-select v-model="searchFrom.uid" placeholder="请选择" style="width: 100%" clearable filterable
                      size="mini">
                      <el-option v-for="(item, index) in user_list" :key="index"
                        :label="`${item.company}`" :value="item.uid">
                        <div class="flex-nowrap-space-between">
                          <div :class="item.status == 1 ? 'no-red' : ''">
                            <span>{{ item.company }}</span>
                            <!-- <span class="ml-5">(Id:{{ item.uid }})</span> -->
                          </div>
                          <div v-if="item.status == 1" class="no-red">禁</div>
                        </div>
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="交易类型：">
                    <el-select v-model="searchFrom.tradeType" placeholder="请选择" style="width: 100%" size="mini">
                      <el-option v-for="(item, index) in [
                          {
                            key: null,
                            name: '全部',
                          },
                          {
                            key: 0,
                            name: '调账',
                          },
                          {
                            key: 1,
                            name: '充值',
                          },
                          {
                            key: 2,
                            name: '消费',
                          },
                          {
                            key: 3,
                            name: '退款',
                          },
                          {
                            key: 4,
                            name: '提现',
                          },
                          {
                            key: 4,
                            name: '转账',
                          },
                        ]" :key="index" :label="item.name" :value="item.key">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="收支类型：">
                    <el-select v-model="searchFrom.tradeDirection" placeholder="请选择" style="width: 100%" size="mini">
                      <el-option v-for="(item, index) in [
                          {
                            key: null,
                            name: '全部',
                          },
                          {
                            key: 0,
                            name: '收入',
                          },
                          {
                            key: 1,
                            name: '支出',
                          },
                        ]" :key="index" :label="item.name" :value="item.key">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="交易时间">
                    <el-date-picker v-model="valueTime" type="datetimerange" start-placeholder="开始日期"
                      end-placeholder="结束日期" clearable value-format="YYYY-MM-DD HH:mm:ss" size="mini"
                      :default-time="defaultTime">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="2">
                  <!-- <el-form-item> -->
                  <div class="title-buttom">
                    <el-button class="cancel" size="mini" @click="reset" round>重置</el-button>
                    <el-button type="primary" size="mini" class="confirmAdd" @click="theQuery" round>查询</el-button>
                  </div>
                  <!-- </el-form-item> -->
                </el-col>
              </el-row>
            </el-form>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <el-row>
      <el-col :span="12">
        <div class="title-name">用户列表</div>
      </el-col>
      <el-col :span="12">
        <div class="title-buttom">
          <el-tooltip content="导出列表" placement="top" effect="light">
            <el-button class="editor" size="mini" icon="el-icon-download" @click="exportTable" circle></el-button>
          </el-tooltip>
          <el-tooltip content="刷新列表" placement="top" effect="light">
            <el-button class="editor" size="mini" icon="el-icon-refresh-right" @click="reset" circle></el-button>
          </el-tooltip>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div>
          <el-table v-loading="loading" ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
            :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }" @selection-change="handleSelectionChange">
            <!-- <el-table-column
                        type="selection"
                        width="55">
                        </el-table-column> -->
            <el-table-column v-if="userType == 'system'" label="公司名称">
              <template #default="scope">{{
                scope.row.company == null ? "-" : scope.row.company
              }}</template>
            </el-table-column>
            <el-table-column v-if="userType == 'system'" label="用户名">
              <template #default="scope">{{
                scope.row.nickName == null ? "-" : scope.row.nickName
              }}</template>
            </el-table-column>
            <el-table-column v-if="userType == 'system'" label="账号">
              <template #default="scope">{{
                scope.row.account == null ? "-" : scope.row.account
              }}</template>
            </el-table-column>
            <el-table-column width="180" label="流水号">
              <template #default="scope">{{
                scope.row.orderNo == null ? "-" : scope.row.orderNo
              }}</template>
            </el-table-column>
            <el-table-column width="180" label="交易时间">
              <template #default="scope">{{
                scope.row.tradeTime == null ? "-" : scope.row.tradeTime
              }}</template>
            </el-table-column>
            <el-table-column label="收支类型">
              <template #default="scope">
                <el-tag :class="
                    scope.row.tradeDirection == 0
                      ? 'income-color'
                      : 'spending-color'
                  " :type="scope.row.tradeDirection == 0 ? 'success' : 'danger'">{{
                    scope.row.tradeDirectionText == null
                      ? "-"
                      : scope.row.tradeDirectionText
                  }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="交易类型">
              <template #default="scope">{{
                scope.row.tradeTypeText == null ? "-" : scope.row.tradeTypeText
              }}</template>
            </el-table-column>
            <el-table-column label="交易渠道">
              <template #default="scope">{{
                scope.row.tradeChannelText == null
                  ? "-"
                  : scope.row.tradeChannelText
              }}</template>
            </el-table-column>
            <el-table-column label="资金形式">
              <template #default="scope">{{
                scope.row.fundTypeText == null ? "-" : scope.row.fundTypeText
              }}</template>
            </el-table-column>
            <el-table-column label="金额">
              <template #default="scope">
                <span :class="
                    scope.row.tradeDirection == 0
                      ? 'income-color'
                      : 'spending-color'
                  ">{{ scope.row.tradeDirection == 0 ? "+" : "-" }}￥{{
                    scope.row.dealAmount == null ? "-" : scope.row.dealAmount
                  }}</span>
              </template>
            </el-table-column>
            <el-table-column label="余额">
              <template #default="scope">
                <span>￥{{
                    scope.row.balance == null ? "-" : scope.row.balance
                  }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作人">
              <template #default="scope">
                <span>{{
                    scope.row.sysNickName == null ? "--" : scope.row.sysNickName
                  }}</span>
              </template>
            </el-table-column>
            <el-table-column width="180" label="交易备注">
              <template #default="scope">
                <el-popover effect="light" trigger="hover" placement="top">
                  <template #default>
                    {{ scope.row.remark == null ? "-" : scope.row.remark }}
                  </template>
                  <template #reference>
                    <div class="name-wrapper">
                      <el-tag size="medium">{{
                        scope.row.remark == null
                          ? "-"
                          : InterceptString(scope.row.remark)
                      }}</el-tag>
                    </div>
                  </template>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template #default="scope">
                <span v-if="scope.row.consumeType == 1" class="button-font-color" @click="showTradeDetails(scope.row)">
                  <icon-font type="icon-jiludanzilishijilu" />
                  查看详情
                </span>
                <span v-else class="button-font-color-err">
                  <icon-font type="icon-zanwujilu" />
                  暂无详情
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <div class="pager">
      <div>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
          background :page-sizes="[10, 20, 30, 40]" :page-size="searchFrom.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import trade from '@/api/web/trade'
import dashboard from '@/api/dashboard/dashboard'
import user from '@/api/web/user'
import userts from '@/api/web/user'
import { IconFont } from '@/utils/iconfont'
import topUp from '@/components/topUp'
export default {
  components: {
    IconFont,
    buy: topUp,
  },
  // computed: {
  //     InterceptString(str){
  //         console.log(str)
  //         // return '备注：'+str.substring(0,6)
  //     }
  // },
  data() {
    return {
         totalBalance:0,
      tableHeight: '',
      searchFrom: {
        page: 1,
        pageSize: 10,
        uid: '',
        tradeDirection: null,
        tradeType: null,
        tradeTimeBegin: '',
        tradeTimeEnd: '',
      }, //搜索值
      currentPage: 1, //分页
      total: 0,
      multipleSelection: [], //选中集合
      valueTime: [], //选中时间段
      defaultTime: [
        new Date(2000, 1, 1, 12, 0, 0),
        new Date(2000, 2, 1, 8, 0, 0),
      ], //默认时间 '12:00:00', '08:00:00'
      activeNames: [], //折叠面板
      tableData: [], //数据列表
      loading: true, // loding 状态启用
      centerDialogVisible: false, //弹窗启用
      userType: '',
      informationList: {
        balance: '',
        topUp: '',
      },
      user_list: [],
       priceMap : {
           0: 0, // 默认值为0元
           1: 0, // 默认值为0元
           2: 0, // 默认值为0元
                           }

    }
  },
  created() {
    this.tableHeight = this.$store.state.tableHeight
  },
  watch: {},
  mounted() {
    if (this.$route.query.currentPage != null) {
      this.searchFrom.page = this.$route.query.currentPage
      this.currentPage = this.$route.query.currentPage
    }
    this.getDataList(true)
    this.userType = this.$store.getters.userType
    this.getData(this.$store.getters.userType)
    if (this.$store.getters.userType == 'system') {
      this.getUserList()
        this.balanceSum1()
    }
  },
  methods: {
    //用户余额查询
    balanceSum1(){
      dashboard.balanceSum({}).then((res)=>{
        if (res.code==200) {
          this.totalBalance = res.data.reduce((acc, obj) => acc + obj.balanceTotal, 0);
       
           res.data.forEach(obj => {
           this.priceMap[obj.customer_type] += obj.balanceTotal;
      });
   
        }
                 
      })
    },

    // 查看明细详情
    showTradeDetails(row) {
      this.$router.push({
        path: '/cost/TradeDetails',
        query: {
          id: row.id,
          currentPage: this.currentPage,
        },
      })
    },
    // 查询
    theQuery() {
      this.getDataList(true)
      this.getData(this.$store.getters.userType)
    },
    getData(userType) {
      if (userType == 'web') {
        user.getInfo({}).then((res) => {
          this.informationList.balance = res.data.balance
        })
      }
      // if (userType == 'system') {

      // }
      dashboard
        .lastMonthConsume({
          uid: this.searchFrom.uid,
        })
        .then((res) => {
          this.informationList.black = Number(res.data.black).toFixed(2)
          this.informationList.privacy = Number(res.data.privacy).toFixed(2)
          this.informationList.voice = Number(res.data.voice).toFixed(2)
          this.informationList.total = Number(res.data.total).toFixed(2)
          this.informationList.lastrentCount = Number(res.data.rentCount)
          this.informationList.lastrentPrice = Number(res.data.rentPrice).toFixed(2)
        })
      dashboard
        .thisMonthConsume({
          uid: this.searchFrom.uid,
        })
        .then((res) => {
          this.informationList.thisblack = Number(res.data.black).toFixed(2)
          this.informationList.thisprivacy = Number(res.data.privacy).toFixed(2)
          this.informationList.thisvoice = Number(res.data.voice).toFixed(2)
          this.informationList.thistotal = Number(res.data.total).toFixed(2)
           this.informationList.rentCount = Number(res.data.rentCount)
          this.informationList.rentPrice = Number(res.data.rentPrice).toFixed(2)
        })
     
      //
      trade.sumMonthAmount({}).then((res) => {
        this.informationList.topUp = res.data
      })
      // trade.sumAmount({
      //     tradeType:1
      // }).then((res) => {
      //     this.informationList.topUp = res.data
      // })
    },
    InterceptString(str) {
      return str.substring(0, 6)
    },
    // 获取用户列表
    getUserList() {
      userts.queryList({}).then((res) => {
        this.user_list = res.data
      })
    },
    // 重置
    reset() {
      this.valueTime = []
      this.searchFrom = {
        page: this.currentPage,
        pageSize: 10,
        tradeDirection: null,
        tradeType: null,
      }
      this.getDataList(true)
      this.getData(this.$store.getters.userType)
    },
    // 获取用户列表
    getDataList(type) {
      this.loading = type
      this.searchFrom.tradeTimeBegin = this.valueTime[0]
      this.searchFrom.tradeTimeEnd = this.valueTime[1]
      trade
        .tradeList({
          ...this.searchFrom,
        })
        .then((res) => {
          this.tableData = res.data.records
          this.total = res.data.total
          this.loading = false
        })
    },
    // 分页
    handleSizeChange(val) {
      this.searchFrom.pageSize = val
      this.getDataList(true)
    },
    handleCurrentChange(val) {
      this.searchFrom.page = val
      this.currentPage = val
      this.getDataList(true)
    },
    // 折叠面板
    handleChange(val) {
      console.log(val)
    },
    // 多选
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 批量删除
    deleteData() {
      console.log(this.multipleSelection)
    },
    // 取消
    cancelEvent() {
      console.log('cancel!')
    },
    // 导出用户列表
    exportTable() {
      this.$confirm('将导出筛选条件内的相关数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          trade
            .tradeExport({
              ...this.searchFrom,
            })
            .then((res) => {
              this.$notify({
                title: '成功',
                message: '列表导出任务已建立！',
                type: 'success',
              })
              this.getDataList(true)
            })
        })
        .catch(() => {
          this.$notify.info({
            title: '通知',
            message: '已取消删除!',
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep(.el-table) {
  font-size: 0.75rem;
}
.pager {
  display: flex;
  justify-content: flex-end;
}
.jobs {
  height: calc(100vh - 135px);
  overflow-y: overlay;
  padding-right: 18px;
  .title-name {
    font-size: 0.9375rem;
    font-weight: 600;
    color: #303133;
  }
  .top-first {
    .search-box {
      width: 18.75rem;
    }
  }
}
.screening {
  margin-bottom: 0.9375rem;
}
.el-row {
  margin-bottom: 0.9375rem;
  &:last-child {
    margin-bottom: 0;
  }
}
.space-tile {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.title-buttom {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}
.editor {
  color: #0170ed;
  background: #e3f0ff;
}
.confirmAdd {
  background: #637dff;
  color: #fff;
}
.cancel {
  color: #637dff;
  border: #637dff solid 1px;
}
.screening-out {
  margin: 0 0.9375rem;
}
.income-color {
  color: #67c23a;
}
.spending-color {
  color: #ff845b;
}

.trabeBoxOut {
  padding: 5px;
  //padding: 0.5rem 1.875rem;
}
.heightBox {
  width: 100%;
  height: 6.25rem;
}
.bottomBg {
  background: #f8f9ff;
  border-radius: 0.8rem;
  margin-bottom: 1.875rem;
}
.trabeImg {
  margin-right: 1.875rem;
}
.price {
  font-size: 2.125rem;
  font-weight: 700;
}
.priceOther {
  font-size: 1.125rem;
  font-weight: 700;
}
.priceOther1 {
font-size: 15px;
font-weight: 700;
}
.unit {
  font-size: 0.875rem;
  font-weight: 600;
}
.trabeItem {
  width: 100%;
  height: 6.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.trabeItem1 {
  width: 100%;
   height: 6.25rem;
   padding: 5px 3px 5px 3px;
  text-align: center;

}
.trabeTitle1{ 
  height: 45px;
  // border: 1px solid red;
}
.flex-start-nowrap {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}
.flex-space-around {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
}
.flex-space-between {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.flex-start-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
</style>

<style lang="scss">
.jobs .el-collapse-item__content {
  padding-bottom: 0 !important;
}
</style>